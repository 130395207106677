import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

function Navbar({ currentSection }) {
  const location = useLocation();
  const [color, setColor] = useState('');

  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='nav_styles'>
      <h1 className='nav_bar_h1'>Kerem Hamza FIRAT</h1>
      <div className='nav_links'>



        <NavLink
          className={({ isActive }) => (isActive ? 'active' : 'default_style')}
          to='/'
          onClick={(e) => {
            if (location.pathname === '/') {
              handleScroll(e, 'home');
            }
          }}
          style={{ color: currentSection === 'home' ? 'white' : 'greenyellow' }}
        >
          Home
        </NavLink>




        <NavLink
          className={({ isActive }) => (isActive ? 'active' : 'default_style')}
          to='/'
          onClick={(e) => {
            if (location.pathname === '/') {
              handleScroll(e, 'about');
              setColor('white');
            } else {
              e.preventDefault();
              window.history.pushState({}, '', '/');
              setTimeout(() => {
                handleScroll(e, 'about');
                setColor('white');
              }, 100);
            }
          }}
          style={{ color: currentSection === 'about' ? 'white' :'greenyellow' }}
        >
          About
        </NavLink>





        <NavLink
          className={({ isActive }) => (isActive ? 'active' : 'default_style')}
          to='/'
          onClick={(e) => {
            if (location.pathname === '/') {
              handleScroll(e, 'contact');
              setColor('white');
            } else {
              e.preventDefault();
              window.history.pushState({}, '', '/');
              setTimeout(() => {
                handleScroll(e, 'contact');
                setColor('white');
              }, 100);
            }
          }}
          style={{ color: currentSection === 'contact' ? 'white' :'greenyellow' }}
        >
          Contact
        </NavLink>




        <NavLink
          className={({ isActive }) => (isActive ? 'active' : 'default_style')}
          to='/'
          onClick={(e) => {
            if (location.pathname === '/') {
              handleScroll(e, 'project');
              setColor('white');
            } else {
              e.preventDefault();
              window.history.pushState({}, '', '/');
              setTimeout(() => {
                handleScroll(e, 'project');
                setColor('white');
              }, 100);
            }
          }}
          style={{ color: currentSection === 'projects' ? 'white' :'greenyellow' }}
        >
          Projects
        </NavLink>



        
      </div>
    </div>
  );
}

export default Navbar;
