import React, { useEffect, useRef } from 'react';
import './App.css'; 

function Home({ setCurrentSection }) {
  const logosRef = useRef([]);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const projectRef = useRef(null);

  useEffect(() => {
    const sections = [
      { id: 'home', ref: homeRef },
      { id: 'about', ref: aboutRef },
      { id: 'contact', ref: contactRef },
      { id: 'project', ref: projectRef }
    ];

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setCurrentSection(entry.target.id);
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    sections.forEach(section => {
      if (section.ref.current) {
        observer.observe(section.ref.current);
      }
    });

    logosRef.current.forEach(logo => {
      if (logo) {
        observer.observe(logo);
      }
    });

    return () => {
      sections.forEach(section => {
        if (section.ref.current) {
          observer.unobserve(section.ref.current);
        }
      });

      logosRef.current.forEach(logo => {
        if (logo) {
          observer.unobserve(logo);
        }
      });
    };
  }, [setCurrentSection]);

  return (
    <div>
      <div id="home" className='home_page_div' ref={homeRef}>
        <img className='img_for_profile' src='/images/keremin.jpeg' alt='Person Photo' />
        <div className='container'>
          <h1 className='home_first_h1 text'>Hello, I am</h1>
          <h1 className='home_second_h1'>Kerem</h1>
          <h1 className='home_third_h1 text'>Full Stack Developer</h1>
        </div>
      </div>

      <div className='qualifications'>
        <h1>Qualifications</h1>
      </div>

      <div className='logos'>
        {['html3.png', 'css33.png', 'javascript3.png', 'react3.png', 'node2.png', 'express2.png', 'npm.png', 'mongo.png'].map((src, index) => (
          <img
            key={index}
            className='logo'
            src={`/images/${src}`}
            alt={`${src.split('.')[0]} Logo`}
            ref={el => (logosRef.current[index] = el)}
          />
        ))}
      </div>

      <div id="about" ref={aboutRef}>
        <h1 className='about_h1'>About</h1>
        <p className='about_p about_first_p'>Hello! I'm Kerem, a dedicated student of Software Engineering at Atilim University.</p>
        <p className='about_p about_second_p'>I'm currently expanding my expertise at the Barcelona Code School, where I'm training to become a full-stack developer.</p>
        <p className='about_p about_third_p'>My journey into coding began in 2021 when I started to teach myself programming.</p>
        <p className='about_p about_forth_p'>Through continuous learning and practical experience, I am working towards mastering the art of building innovative software solutions.</p>
      </div>

      <div id="contact" ref={contactRef}>
        <h1 className='contact'>Contact</h1>
        <div className='mail'>
          <h2>Email</h2>
        </div>
        <p className='mail_p'>keremfiratt49@gmail.com</p>
        <div className='contact_info'>
          <div className='linkedin'>
            <h2>LinkedIn</h2>
            <a href="https://www.linkedin.com/in/keremhamzafirat/" target="_blank" rel="noopener noreferrer" className='linkedin_p'>View</a>
          </div>
          <div className='github'>
            <h2>GitHub</h2>
            <a href="https://github.com/kermfiratt" target="_blank" rel="noopener noreferrer" className='github_p'>View</a>
          </div>
        </div>
      </div>

      <div id='project' ref={projectRef}>
        <div>
          <h1 className='projects'>Projects</h1>
        </div>
        <div className='tasteterra'>
          <h2 className='orange'>Taste Terra</h2>
          <h3>About Taste Terra</h3>
          <p>Simply search for your favorite meals or ingredients, and we'll provide you with the best recipes and cooking instructions.</p>
          <p>Discover our recommendations in world cuisines and search for meals around the globe.</p>
        </div>
        <div className='grid_photo'>
          <img className='img_for_tt' src='/images/tt1.jpg' alt='Taste Terra Image 1' />
          <img className='img_for_tt' src='/images/tt2.jpg' alt='Taste Terra Image 2' />
          <img className='img_for_tt' src='/images/tt3.jpg' alt='Taste Terra Image 3' />
          <img className='img_for_tt' src='/images/tt4.jpg' alt='Taste Terra Image 4' />
          <img className='img_for_tt' src='/images/tt5.jpg' alt='Taste Terra Image 5' />
        </div>
      </div>

      <div className='ttalign'>
        <h2>LINK FOR TASTE TERRA</h2>
        <a href="https://www.tasteterra.com/" target="_blank" rel="noopener noreferrer" className='linkedin_p'>View</a>
      </div>

      <div>
        <footer>
          <p>Kerem Hamza FIRAT &copy; 2024</p>
        </footer>
      </div>
    </div>
  );
}

export default Home;
