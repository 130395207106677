import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Navbar from './Navbar';
import './App.css';

function App() {
  const [currentSection, setCurrentSection] = useState('home');

  return (
    <div>
      <Router>
        <Navbar currentSection={currentSection} />
        <Routes>
          <Route path='/' element={<Home setCurrentSection={setCurrentSection} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
